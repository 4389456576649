<template>
    <div>
        <b-row>
            <b-col>
                <router-link to="/content/faqManagement/new"><b-button variant="orange" class="mr-1" size="sm"><font-awesome-icon icon="plus"/> {{ $t('pages.content.faqManagement.buttons.create') }}</b-button></router-link>
                <b-button variant="danger" size="sm" class="mr-1" :disabled="selectedItems.length <= 0" v-b-modal.delete-modal>{{ $t('buttons.delete') }}</b-button>
            </b-col>
            <b-col sm="4" md="5" xl="4" class="my-1">
                <b-form-group
                    label-for="filter-input"
                    label-cols-sm="3"
                    label-align-sm="right"
                    label-size="sm"
                    class="mb-0"
                >
                    <b-input-group size="sm">
                        <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            :placeholder="$t('generally.tables.tipToSearch')"
                        ></b-form-input>

                        <b-input-group-append>
                            <b-button :disabled="!filter" @click="filter = ''">{{ $t('generally.tables.clear') }}</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </b-col>
        </b-row>
        <b-row>
            <b-col cols="12">
                <b-table
                    :items="items"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    @filtered="onFiltered"
                    stacked="md"
                    :empty-text="$t('generally.tables.emptyText')"
                    :empty-filtered-text="$t('generally.tables.emptyFilteredText')"
                    show-empty
                    striped
                    selectable
                    select-mode="multi"
                    ref="poolTable"
                    class="bg-white"
                    @row-selected="onSelected"
                    sort-by="question"
                >
                    <template #head(selected)="{}">
                        <template v-if="selectAll">
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true" href="#" @click.prevent="checkAll()"><font-awesome-icon :icon="['far', 'square']"/></span>
                        </template>
                    </template>

                    <template #cell(selected)="{ rowSelected }">
                        <template v-if="rowSelected">
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'check-square']"/></span>
                            <span class="sr-only">Selected</span>
                        </template>
                        <template v-else>
                            <span aria-hidden="true"><font-awesome-icon :icon="['far', 'square']"/></span>
                            <span class="sr-only">Not selected</span>
                        </template>
                    </template>

                    <template #cell(question)="row">
                        <img :src="row.item.image" v-show="row.item.imageLoad" width="20" height="20" @load="row.item.imageLoad = true" @error="row.item.imageLoad = false">
                        <b-icon-question-circle v-show="!row.item.imageLoad" class="text-orange"/>
                        {{ row.item.question[getLocale()] }}
                    </template>

                    <template #cell(tags)="row">
                        {{ row.item.tags.slice(0, 5).join(', ') }}<span v-if="row.item.tags.length > 5">, ...</span>
                    </template>

                    <template #cell(public)="row">
                        <font-awesome-icon :class="[row.item.public ? 'text-success' : 'text-danger']" :icon="row.item.public ? 'check' : 'times'"/>
                    </template>

                    <template #cell(createdAt)="row">
                        {{ $moment(row.item.createdAt).format('DD.MM.YYYY HH:mm') }}
                    </template>

                    <template #cell(owner)="row">
                        {{ row.item.owner.firstName }} {{ row.item.owner.lastName }}
                    </template>

                    <template #cell(actions)="row">
                        <b-button variant="primary" :to="{ name: 'ContentFaqManagementDetail', params: { id: row.item.id}}" class="btn-xs mb-0 ml-1" :title="$t('buttons.show')">
                            <font-awesome-icon icon="eye"/>
                        </b-button>
                        <b-button variant="orange" :to="{ name: 'ContentFaqManagementEdit', params: { id: row.item.id}}" class="btn-xs mb-0 ml-1" :title="$t('buttons.edit')">
                            <font-awesome-icon icon="pencil-alt"/>
                        </b-button>
                    </template>
                    <template #row-details="row">
                        <b-card>
                            <ul>
                                <li v-for="(value, key) in row.item" :key="key">{{ key }}: {{ value }}</li>
                            </ul>
                        </b-card>
                    </template>
                </b-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <b-pagination
                    v-model="currentPage"
                    :total-rows="totalRows"
                    :per-page="perPage"
                    align="right"
                    size="sm"
                    class="my-0"
                ></b-pagination>
            </b-col>
        </b-row>
        <b-modal
            id="delete-modal"
            ref="delete-modal"
            :title="$t('pages.content.faqManagement.headers.delete')"
            :ok-title="$t('buttons.delete')"
            :cancel-title="$t('buttons.cancel')"
            ok-variant="danger"
            @ok="onDelete"
        >
            {{ $t('pages.content.faqManagement.messages.delete.' + (selectedItems.length > 1 ? 'multi' : 'single'), {count: selectedItems.length}) }}
        </b-modal>
    </div>
</template>

<script>
import faqService from "../../../services/faqService";
import mainServices from "../../../services/mainServices";
import {EventBus} from "../../../services/EventBus";

export default {
    name: "FaqManagementList",
    data: () => ({
        fields: [],
        totalRows: 1,
        currentPage: 1,
        perPage: 25,
        pageOptions: [5, 10, 15, 25, 50],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        selectAll: false,
        selectedItems: [],
        items: [],
        eventBus: null
    }),
    mounted() {
        this.fields = [
            {key: 'selected', label: '', sortable: false},
            {key: 'question', label: this.$t('pages.content.faqManagement.table.question'), sortable: true, sortDirection: 'asc'},
            {key: 'tags', label: this.$t('pages.content.faqManagement.table.tags'), sortable: true, sortDirection: 'asc'},
            {key: 'public', label: this.$t('generally.tables.public'), sortable: true, sortDirection: 'asc'},
            {key: 'createdAt', label: this.$t('generally.tables.createdAt'), sortable: true},
            {key: 'owner', label: this.$t('generally.tables.createdFrom'), sortable: true},
            {key: 'actions', label: this.$t('generally.tables.actions')}
        ]
        this.load();
        // adding EventBus listener
        this.eventBus = EventBus.$on('sync', () => {
            this.load();
        });

    },
    beforeDestroy() {
        // removing EventBus listener
        EventBus.$off('sync', this.eventBus);
    },
    methods: {
        getLocale(){
            return mainServices.getLocale();
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        load() {
            faqService.list().then(response => {
                this.items = response.data.map(item => {
                    item.imageLoad = false;
                    return item;
                });
                this.totalRows = this.items.length;
            }).catch(() => {
                this.items = [];
                this.totalRows = this.items.length;
            });
        },
        checkAll(){
            this.selectAll = !this.selectAll;
            if(this.selectAll){
                this.$refs.poolTable.selectAllRows()
                this.selectedItems = this.items;
            } else {
                this.$refs.poolTable.clearSelected()
                this.selectedItems = [];
            }
        },
        onSelected(items){
            this.selectAll = this.items.length === items.length;
            this.selectedItems = items;
        },
        onDelete(){
            faqService.delete({ ids: this.selectedItems.map(item => (item.id)) }).then(() => {
                this.$toastr.s(this.$t('pages.content.faqManagement.messages.success.deleted'));
                this.load();
            }).catch(error => {
                this.errorHandler(error)
            });
        }
    }
};
</script>

<style scoped>

</style>
